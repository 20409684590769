import {html, css, LitElement} from 'lit';
import $ from 'jquery';
import select2 from "select2";
import {PropertyValues} from "@lit/reactive-element";
import {property} from 'lit/decorators.js';
import {PromiseLater} from "./util/promise-later";

// Register select2 with jQuery
try {
// @ts-ignore
  select2($);
}catch (e) {
  // Sometimes this isn't necessary
}

export class CbarSelect2 extends LitElement {
  static styles = css`
    :host {
      display: inline-block;

      --underline-color: var(--select-2-underline-color, white);
    }

    :host([loading]) {
      /* Don't flash unstyled content */
      display: none;
    }

    select {
      width: 100%;
    }

    /* select2 arrow */
    .select2-container .select2-selection--single .select2-selection__arrow {
      background: var(
        --select2-arrow-url,
        url("data:image/svg+xml;charset=utf-8,%3Csvg width='10' height='5' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' fill-rule='evenodd' d='m7 10 5 5 5-5z'/%3E%3C/svg%3E") no-repeat 50%
      )
    }

    /* select2 textfield */
    .select2-container .select2-selection {
      color: var(--select2-text-color, white);
      border-color: var(--underline-color);
      max-width: var(--select2-text-max-width, 300px);
    }

    .select2-container .select2-selection .select2-selection__rendered {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .select2-container .select2-selection:hover {
      border-color: var(--underline-color);
    }

    .select2-container .select2-selection:before {
      color: var(--underline-color);
    }

    /* select2 results */
    .select2-results {
      color: var(--select2-dropdown-text-color, black);
    }

    .select2-results > .select2-results__options {
      max-height: var(--select2-dropdown-max-height, 500px);
      white-space: normal;
    }

    .select2-results .select2-results__options--nested {
      /* nested options should not have a max height so that the results are not cropped */
      max-height: unset;
    }

    .select2-container--open .select2-dropdown {
      width: var(--select2-dropdown-width, auto);
      min-width: var(--select2-dropdown-min-width, 250px);
      max-width: var(--select2-dropdown-max-width, 450px);
    }
  `;

  @property({type: String})
  cssPath = '/node_modules/djibe-material/css/material-plugins.min.css';

  @property({type: String})
  value = '';

  @property({type: Boolean, reflect: true})
  loading = true;

  @property({type: String, reflect: true})
  name = '';

  @property({type: String})
  theme: Theme;

  private styleSheetLoaded = new PromiseLater;
  private select2Ele: any;
  private selectEle?: HTMLSelectElement;
  private afterFirstUpdate = new PromiseLater;

  constructor() {
    super();

    document.addEventListener('click', e => {
      if (e.target !== this) {
        if (!this.select2Ele) {
          return;
        }

        this.select2Ele.select2('close');
      }
    });
  }

  async firstUpdated(changedProps: PropertyValues) {
    super.firstUpdated(changedProps);

    const shadowRoot = this.shadowRoot!;
    const selectEle = this.selectEle = shadowRoot.querySelector('select')!;
    const container = shadowRoot.querySelector('#container') as HTMLElement;

    await this.styleSheetLoaded;

    let $select2 = this.select2Ele = $(selectEle);
    $select2.select2({
      dropdownParent: $(container),
      theme: this.theme,
    });

    this.loading = false;

    // Set initial value
    this.value = $select2.val() as string;

    $select2.on('change', e => {
      this.value = $select2.val() as string;
      this.dispatchEvent(new CustomEvent('change'));
    });

    // Ensure that the select element is accessible by forms using this
    // component
    this.moveSelectToLightDom(selectEle);

    // Fix a bug where the search is not focused when opening
    // the dropdown
    this.focusOnOpen($(this));

    this.afterFirstUpdate.resolve();
  }

  onStylesheetLoaded() {
    this.styleSheetLoaded.resolve();
  }

  focusOnOpen($container: JQuery) {
    $container.on('select2:open', () => {
      const searchField = this.shadowRoot!.querySelector('.select2-search__field') as HTMLInputElement;
      searchField.focus();
    });
  }

  async moveSlottedOptions() {
    await this.afterFirstUpdate;

    const shadowRoot = this.shadowRoot!;

    const optionsSlot = shadowRoot.querySelector('#optionsSlot') as HTMLSlotElement;
    const options = optionsSlot.assignedElements({flatten: true}) as HTMLOptionElement[];

    options.forEach(option => {
      if (option.nodeName !== 'OPTION') {
        return;
      }

      const selectEle = this.selectEle!;
      selectEle.appendChild(option)
    });
  }

  moveSelectToLightDom(selectEle: HTMLSelectElement) {
    selectEle.style.display = 'none';
    this.appendChild(selectEle);
  }

  render() {
    return html`
      <link rel="stylesheet" href="${this.cssPath}" @load=${this.onStylesheetLoaded}>

      <div id="container">
        <select name="${this.name}"></select>
      </div>

      <slot id="optionsSlot" @slotchange=${this.moveSlottedOptions}></slot>
    `;
  }
}

type Theme = undefined | 'filled' | 'outlined';
