import '@cbar/cbar-header/cbar-header.js'
import '@cbar/cbar-header/cbar-list-item.js'
import '@cbar/cbar-header/cbar-list-item-group.js'
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-button/paper-button.js';
import '@polymer/polymer/lib/elements/custom-style.js'
import '@polymer/paper-card/paper-card.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/font-roboto/roboto.js';
import '@polymer/iron-form/iron-form.js';
import '@webcomponents/webcomponentsjs/webcomponents-loader.js'
import '@polymer/paper-dropdown-menu/paper-dropdown-menu.js';
import '@polymer/paper-listbox/paper-listbox.js';
import '@polymer/paper-item/paper-item.js';
import '@polymer/paper-spinner/paper-spinner.js';
import '@cbar/cbar-paymentmethod-modal/cbar-paymentmethod-form.js';
import '@polymer/paper-checkbox/paper-checkbox.js';
import '@cbar/cbar-select2/cbar-select2.js';
import '@polymer/iron-icons/iron-icons.js';
import '@polymer/iron-icons/social-icons.js';
import '@polymer/iron-icons/av-icons.js';
import '@polymer/iron-icons/editor-icons.js';
import '@polymer/iron-icons/maps-icons.js';
import '@polymer/iron-icons/image-icons.js';
import '@polymer/iron-icons/communication-icons.js';
import '@polymer/iron-icons/device-icons.js';
